import { useState } from "react";
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import "./Signup.css";

export default function SignUp() {
    const [email, setEmail] = useState("");
    const [nickname, setNickname] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const navigate = useNavigate();

    function registerHandler(event) {
        event.preventDefault();
        if (
            email === "" ||
            nickname === "" ||
            password === "" ||
            passwordConfirm === ""
        ) {
            alert("모든 정보를 입력해주세요!");
            return;
        }
        
        if (password !== passwordConfirm) {
            alert("비밀번호를 다르게 입력했습니다!");
            return;
        }

        alert("회원가입이 완료되었습니다!");
        navigate('/login'); 
    }

    return (
        <div className="wrapper">
            <div className="login-wrapper">
                <h2>회원가입</h2>
                <form id="login-form" onSubmit={registerHandler}>
                    <input
                        type="email"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Nickname"
                        onChange={(e) => setNickname(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Password Confirm"
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                    />
                    <input type="submit" value="회원가입" />
                    <p>
                        <Link to="/">메인 화면으로 돌아가기</Link>
                    </p>
                </form>
            </div>
        </div>
    );
}
