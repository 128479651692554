// src/Map.js
import React, { useEffect, useRef } from 'react';

const NaverMapComponent = () => {
    const mapElement = useRef(null); // 지도를 렌더링할 DOM 요소를 참조하기 위한 ref

    useEffect(() => {
        // Naver Maps API가 로드된 후 실행되는 함수
        const initializeMap = () => {
            if (window.naver && window.naver.maps) {
                const mapOptions = {
                    center: new window.naver.maps.LatLng(35.143165, 129.095479),
                    zoom: 16,
                };
                const map = new window.naver.maps.Map(mapElement.current, mapOptions);

                new window.naver.maps.Marker({
                    position: new window.naver.maps.LatLng(35.143165, 129.095479),
                    map: map,
                    animation: window.naver.maps.Animation.BOUNCE,
                    title: '경성대학교'
                });
            } else {
                console.error("Naver Maps API가 로드되지 않았습니다.");
            }
        };

        if (window.naver && window.naver.maps) {
            initializeMap();
        }
    }, []); 

    return (
        <div
            ref={mapElement}
            style={{ width: '100%', height: '500px' }} // 지도의 크기 설정
        />
    );
};

const Map = () => {
    return (
        <div>
            <div className="procfessorIntro">경성대학교 공학관</div>
            <NaverMapComponent />
        </div>
    );
};

export default Map;
