import React, { useState } from 'react';
import "./News.css";
import PostInputContainer from './PostInputContainer';
import PostList from './PostList';

const DepartmentNews = () => {
    const [posts, setPosts] = useState([]);
    const [newPostText, setNewPostText] = useState("");
    const [newPostTitle, setNewPostTitle] = useState("");
    const [isCreatingPost, setIsCreatingPost] = useState(false); // 상태 추가

    // 게시물 작성 입력 핸들러
    const handlePostTextChange = (event) => {
        setNewPostText(event.target.value);
    };

    const handlePostTitleChange = (event) => {
        setNewPostTitle(event.target.value);
    };

    // 게시물 제출 핸들러
    const handlePostSubmit = () => {
        if (newPostTitle.trim() !== "" && newPostText.trim() !== "") {
            const newPost = {
                title: newPostTitle,
                text: newPostText,
                date: new Date().toLocaleDateString(),
                comments: []
            };
            setPosts([newPost, ...posts]);
            setNewPostTitle("");
            setNewPostText("");
            setIsCreatingPost(false); // 게시물 작성 후 PostList로 이동
        }
    };

    // 게시물 삭제 핸들러
    const handlePostDelete = (index) => {
        const updatedPosts = [...posts];
        updatedPosts.splice(index, 1);
        setPosts(updatedPosts);
    };

    // 댓글 제출 핸들러
    const handleCommentSubmit = (index) => (event) => {
        event.preventDefault();
        const commentText = event.target.comment.value;
        if (commentText.trim() !== "") {
            const updatedPosts = [...posts];
            updatedPosts[index].comments = [commentText, ...updatedPosts[index].comments];
            setPosts(updatedPosts);
            event.target.comment.value = "";
        }
    };

    // 댓글 삭제 핸들러
    const handleCommentDelete = (postIndex, commentIndex) => {
        const updatedPosts = [...posts];
        updatedPosts[postIndex].comments.splice(commentIndex, 1);
        setPosts(updatedPosts);
    };

    // "새 글 작성" 버튼 클릭 핸들러
    const handleWriteNewPost = () => {
        setIsCreatingPost(true); // PostInputContainer로 전환
    };

    return (
        <div className="department-news">
            <div className="newsname">게시판</div>
            {isCreatingPost ? (
                <PostInputContainer
                    newPostTitle={newPostTitle}
                    handlePostTitleChange={handlePostTitleChange}
                    newPostText={newPostText}
                    handlePostTextChange={handlePostTextChange}
                    handlePostSubmit={handlePostSubmit}
                />
            ) : (
                <>
                    <PostList
                        posts={posts}
                        handlePostDelete={handlePostDelete}
                        handleCommentSubmit={handleCommentSubmit}
                        handleCommentDelete={handleCommentDelete}
                    />
                    <button className="write-new-post-button" onClick={handleWriteNewPost}>새 글 작성</button>
                </>
            )}
        </div>
    );
};

export default DepartmentNews;