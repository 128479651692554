import React, { useState, useEffect, useMemo } from 'react';
import './Home.css';

const Home = () => {
    const colors = useColorGeneration();
    const [currentColorIndex, setCurrentColorIndex] = useState(0);
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentColorIndex(currentIndex => (currentIndex + 1) % colors.length);
        }, 100);
        return () => clearInterval(interval);
    }, [colors.length]);

    useEffect(() => {
        const image = new Image();
        image.src = 'https://jcy010905.github.io/Department/images/desk.jpg';
        image.onload = () => {
            setImageLoaded(true);
        };
    }, []);

    function useColorGeneration() {
        return useMemo(() => {
            const colors = [];
            const step = 255 / 100;
            let redValue = 255;
            let greenValue = 255;
            let blueValue = 255;
            for (let i = 0; i < 100; i++) {
                const hexColor = `#${Math.round(redValue).toString(16).padStart(2, '0')}${Math.round(greenValue).toString(16).padStart(2, '0')}${Math.round(blueValue).toString(16).padStart(2, '0')}`;
                colors.push(hexColor);
                blueValue -= step;
            }
            for (let i = 0; i < 100; i++) {
                const hexColor = `#${Math.round(redValue).toString(16).padStart(2, '0')}${Math.round(greenValue).toString(16).padStart(2, '0')}${Math.round(blueValue).toString(16).padStart(2, '0')}`;
                colors.push(hexColor);
                blueValue += step;
            }
            return colors;
        }, []);
    }

    return (
        <div className="main-home" style={{ backgroundImage: imageLoaded ? 'url(https://jcy010905.github.io/Department/images/desk.jpg)' : 'none' }}>
            <div className="top" style={{ color: colors[currentColorIndex] }}>KYUNGSUNG UNIVERSITY</div>
            <div className="center" style={{ color: colors[currentColorIndex] }}>상상을 현실로 만드는 학과</div>
            <div className="center" style={{ color: colors[currentColorIndex] }}>함께하는 혁신</div>
            <div className="bottom" style={{ color: colors[currentColorIndex] }}>A DEPARTMENT THAT MAKES IAMGINATION A REALITY</div>
        </div>
    );
};

export default Home;
