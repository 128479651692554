import React from 'react';

const PostList = ({ posts, handlePostDelete, handleCommentSubmit, handleCommentDelete, handleWriteNewPost }) => {
    return (
        <div className="post-list">
            {posts.map((post, postIndex) => (
                <div key={postIndex} className="post">
                    <div className="post-content">
                        <div className="post-title">{post.title}</div>
                        <div className="post-text">{post.text}</div>
                        <div className="post-date">작성일: {post.date}</div>
                        <button className="delete-post-button" onClick={() => handlePostDelete(postIndex)}>삭제</button>
                    </div>
                    <form onSubmit={handleCommentSubmit(postIndex)}>
                        <div className="comment-input-container">
                            <input type="text" name="comment" placeholder="댓글을 입력하세요..." />
                            <button type="submit">댓글 작성</button>
                        </div>
                    </form>
                    <div className="comment-list">
                        {post.comments.map((comment, commentIndex) => (
                            <div key={commentIndex} className="comment">
                                {comment}
                                <button className="delete-comment-button" onClick={() => handleCommentDelete(postIndex, commentIndex)}>삭제</button>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default PostList;
