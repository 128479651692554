import React from 'react';
import "./Subjects.css"

const Subjects = () => {
    return (
        <>
        <div className="subjectname">학과 개설 과목</div>
        <div className="subjectBox">
            <div className="year">1학년</div>
            <div className="semesterBox">
                <div className="semester">1학기</div>
                <div className="subject">전산학개론</div>
                <div className="subject">이산수학</div>
                <div className="subject">오픈소프트웨어</div>
                <div className="subject">전공기초설계</div>
            </div>
            <div className="semesterBox">
                <div className="semester2">2학기</div>
                <div className="subject">프로그래밍 기초</div>
                <div className="subject">확률통계</div>
                <div className="subject">논리회로설계</div>
                <div className="subject2"></div>
            </div>
        </div>
        <div className="subjectBox">
            <div className="year">2학년</div>
            <div className="semesterBox">
                <div className="semester">1학기</div>
                <div className="subject3">자바프로그래밍</div>
                <div className="subject3">자료구조</div>
                <div className="subject3">어셈블리프로그래밍</div>
                <div className="subject3">전산수학</div>
                <div className="subject">계산이론</div>
                <div className="subject">컴퓨터구조</div>
                <div className="subject2"></div>
                <div className="subject"></div>
            </div>
            <div className="semesterBox">
                <div className="semester2">2학기</div>
                <div className="subject3">운영체제</div>
                <div className="subject3">자바프로그래밍응용</div>
                <div className="subject3">알고리즘</div>
                <div className="subject4">문제해결기법</div>
                <div className="subject">웹프로그래밍</div>
                <div className="subject">파일처리론</div>
                <div className="subject2"></div>
                <div className="subject2"></div>
            </div>
        </div>
        <div className="subjectBox">
            <div className="year">3학년</div>
            <div className="semesterBox">
                <div className="semester">1학기</div>
                <div className="subject3">데이터베이스</div>
                <div className="subject3">컴퓨터네트워크</div>
                <div className="subject3">웹프로그래밍응용</div>
                <div className="subject3">시스템프로그래밍</div>
                <div className="subject">객체지향프로그래밍</div>
                <div className="subject">머신러닝기초</div>
                <div className="subject">지식재산개론(IPAT)</div>
                <div className="subject"></div>
            </div>
            <div className="semesterBox">
                <div className="semester2">2학기</div>
                <div className="subject3">프로그래밍언어론</div>
                <div className="subject3">게임프로그래밍</div>
                <div className="subject3">정보보안</div>
                <div className="subject4">인공지능</div>
                <div className="subject">데이터베이스응용</div>
                <div className="subject">모바일프로그래밍</div>
                <div className="subject2"></div>
                <div className="subject2"></div>
            </div>
        </div>
        <div className="subjectBox">
            <div className="year">4학년</div>
            <div className="semesterBox">
                <div className="semester">1학기</div>
                <div className="subject">고급프로그래밍</div>
                <div className="subject">프로젝트(캡스톤디자인)</div>
                <div className="subject">정보검색</div>
                <div className="subject">IP정보검색과분석(IPAT)</div>
            </div>
            <div className="semesterBox">
                <div className="semester2">2학기</div>
                <div className="subject">병렬컴퓨팅</div>
                <div className="subject">소프트웨어공학</div>
                <div className="subject2"></div>
                <div className="subject2"></div>
            </div>
        </div>
        </>
    );
};

export default Subjects;
