import React from 'react';
import "./Intro.css"

const DepartmentInfo = () => {
  return (
    <intro>
      <div className='intro-name'> 학과 소개</div>
      <img className="intro-image" src="https://jcy010905.github.io/Department/images/intro.jpg" alt="소개"/>
    </intro>
  );
};

export default DepartmentInfo;