import React from 'react';

const PostInputContainer = ({ newPostTitle, handlePostTitleChange, newPostText, handlePostTextChange, handlePostSubmit }) => {
    return (
        <div className="post-box">
            <div className="post-input-container">
                <input
                    type="text"
                    className="post-title-input"
                    value={newPostTitle}
                    onChange={handlePostTitleChange}
                    placeholder="제목을 입력하세요..."
                />
                <textarea
                    className="post-input"
                    value={newPostText}
                    onChange={handlePostTextChange}
                    placeholder="게시글을 입력하세요..."
                />
            </div>
            <button type="button" className="post-button" onClick={handlePostSubmit}>게시</button>
        </div>
    );
};

export default PostInputContainer;