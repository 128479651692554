import React from 'react';
import './Faculty.css'

const Faculty = () => {
    return (
        <main className="facultyContainer">
            <div className="procfessorIntro">교수진 소개</div>
            <div className="professorBox">
                <h3>양태천 교수</h3>
                <p>양태천 교수님은 이런 정보를 갖고 있습니다.</p>
            </div>
            <div className="professorBox">
                <h3>성낙운 교수</h3>
                <p>성낙운 교수님은 이런 정보를 갖고 있습니다.</p>
            </div>
            <div className="professorBox">
                <h3>홍석희 교수</h3>
                <p>홍석희 교수님은 이런 정보를 갖고 있습니다.</p>
            </div>
            <div className="professorBox">
                <h3>지상문 교수</h3>
                <p>지상문 교수님은 이런 정보를 갖고 있습니다.</p>
            </div>
            <div className="professorBox">
                <h3>강인수 교수</h3>
                <p>강인수 교수님은 이런 정보를 갖고 있습니다.</p>
            </div>
            <div className="professorBox">
                <h3>김주현 교수</h3>
                <p>김주현 교수님은 이런 정보를 갖고 있습니다.</p>
            </div>
            <div className="professorBox">
                <h3>최동한 교수</h3>
                <p>최동한 교수님은 이런 정보를 갖고 있습니다.</p>
            </div>
            <div className="professorBox">
                <h3>정문주 교수</h3>
                <p>정문주 교수님은 이런 정보를 갖고 있습니다.</p>
            </div>
        </main>
    );
};

export default Faculty;
