import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Mypage.css';

const Mypage = ({ handleLogout }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [name, setName] = useState('홍길동');
    const [newName, setNewName] = useState(name);
    const navigate = useNavigate();

    const handleEditProfile = () => {
        setIsEditing(true);
    };

    const handleSaveProfile = () => {
        setName(newName);
        setIsEditing(false);
    };

    const handleCloseModal = () => {
        setIsEditing(false);
    };

    const handleLogoutClick = () => {
        handleLogout(); // handleLogout을 호출하여 로그아웃 처리
        navigate('/'); // 홈 페이지로 이동
    };

    return (
        <div className="mypage">
            <div className="my-name">마이페이지</div>
            <div className="profile">
                <img src="https://jcy010905.github.io/Department/images/profile.png" alt="프로필 이미지" />
                <div className="profile-info">
                    <p>이름: {name}</p>
                    <p>이메일: honggildong@exampel.com</p>
                </div>
                <div className="profile-buttons">
                    <button onClick={handleEditProfile}>프로필 수정</button>
                    <button onClick={handleLogoutClick}>로그아웃</button>
                </div>
            </div>

            {isEditing && (
                <div className="modal">
                    <div className="modal-content">
                        <button className="close-button" onClick={handleCloseModal}>×</button>
                        <h2>프로필 수정</h2>
                        <input 
                            type="text" 
                            value={newName} 
                            onChange={(e) => setNewName(e.target.value)} 
                            placeholder="이름"
                        />
                        <button className="save-button" onClick={handleSaveProfile}>저장</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Mypage;
