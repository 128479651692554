import { useState } from "react";
import { Link, useNavigate } from 'react-router-dom'; // Link 추가
import "./Signup.css";

export default function Login({ handleLogin }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    function loginHandler(event) {
        event.preventDefault();
        if (
            email === "" ||
            password === ""
        ) {
            alert("모든 정보를 입력해주세요!");
            return;
        }

        handleLogin();
        navigate('/');
    }

    return (
        <div className="wrapper">
            <div className="login-wrapper">
                <h2>로그인</h2>
                <form id="login-form" onSubmit={loginHandler}>
                    <input
                        type="email"
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <input type="submit" value="로그인" />
                    <p>
                        <Link to="/">메인 화면으로 돌아가기</Link>
                    </p>
                </form>
            </div>
        </div>
    );
}
