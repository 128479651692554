import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';

const Navbar = ({ isLoggedIn, handleLogout }) => {
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    navigate('/');
    handleLogout();
  };
  
  return (
    <nav className="navbar">
      {isLoggedIn ? (
        <>
          <img className="navbar-logo" src="https://jcy010905.github.io/Department/images/logo.png" alt="Logo" />
        </>
      ) : (
        <>
          <img className="navbar-logo" src="https://jcy010905.github.io/Department/images/logo.png" alt="Logo" />
          <div className="navbar-name">소프트웨어학과</div>
        </>
      )}

      <ul className="navbar-links">
        {isLoggedIn ? (
          <>
            <li><button className="navbar-btn" onClick={() => navigate('/')}>홈</button></li>
            <li><button className="navbar-btn" onClick={() => navigate('/mypage')}>마이페이지</button></li>
            <li><button className="navbar-btn" onClick={() => navigate('/department-intro')}>학과 소개</button></li>
            <li><button className="navbar-btn" onClick={() => navigate('/faculty')}>교수진 소개</button></li>
            <li><button className="navbar-btn" onClick={() => navigate('/subjects')}>개설 과목</button></li>
            <li><button className="navbar-btn" onClick={() => navigate('/news')}>게시판</button></li>
            <li><button className="navbar-btn" onClick={() => navigate('/map')}>지도</button></li>
            <li><button className="navbar-btn" onClick={handleLogoutClick}>로그아웃</button></li>
          </>
        ) : (
          <>
            <li><button className="navbar-btn" onClick={() => navigate('/login')}>로그인</button></li>
            <li><button className="navbar-btn" onClick={() => navigate('/signup')}>회원가입</button></li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
